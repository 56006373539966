import React from 'react'
import { Link, navigate } from 'gatsby'
import isBrowser from '../utils/isBrowser';
import styled from 'styled-components'

import AppContext from '@contexts/AppContext';
import { handleLogin, handleCreateUser, isLoggedIn } from '../services/auth'
import FormGroup from '../components/FormGroup'
import { Close } from '../assets/svgs/Close'

import { cubicBezier, MQ } from '../assets/styles/constants'

class Login extends React.Component {
  state = {
    email: '',
    username: '',
    password: '',
    form: 'login',
    message: '',
    error: null,
    submitting: false,
  }

  componentDidMount() {
    if (isLoggedIn() && isBrowser()) {
      navigate(`/`)
    }
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleLogin = (event, setAppIsReady) => {
    event.preventDefault();
    const { username, password } = this.state;

    const onSuccess = () => {
      setAppIsReady(true);

      const savedRedirectUrl = localStorage.getItem('REDIRECT_URL');

      if (savedRedirectUrl) {
        localStorage.removeItem('REDIRECT_URL');
        navigate(savedRedirectUrl);
      } else {
        navigate(`/`);
      }
    }

    const onError = error => {
      this.setState({
        error,
        submitting: false,
      });
    }

    if (!username || !password) {
      this.setState({
        error: 'All fields must be completed',
        message: '',
      });
    } else {
      this.setState({
        error: '',
        message: '',
        submitting: true,
      });

      handleLogin(username, password, onSuccess, onError);
    }

    return false;
  }

  renderLoginForm = () => {
    const { error, username, password, submitting, message } = this.state;
    const buttonDisabled = (!username || !password) || submitting;

    return (
      <AppContext.Consumer>
        {
          ({ setAppIsReady }) => (
            <Form onSubmit={e => this.handleLogin(e, setAppIsReady)}>
              <Headline>
                Enter <strong>Login</strong>
              </Headline>
              <Sub href="#" onClick={() => this.setState({ form: 'request'})}>
                <strong>No login?</strong> Click here to request access.
              </Sub>
              { message && <Message>{message}</Message> }
              <FormGroup formOnChange={this.handleInputChange('username')} type="text" label="Username" name="username" value={username} />
              <FormGroup formOnChange={this.handleInputChange('password')} type="password" label="Password" name="password" value={password} />
              <Sub target="_blank" rel="noopener noreferrer" href={`${process.env.GATSBY_WORDPRESS_SOURCE}/wp-login.php?action=lostpassword`}>
                <strong>Forgot password?</strong> Click <strong>here</strong> to reset.
              </Sub>
              {error && <FormError>Error: {error}</FormError>}
              <FormSubmit disabled={buttonDisabled}>{submitting ? 'Loading' : 'Login'}</FormSubmit>
            </Form>
          )
        }
      </AppContext.Consumer>
    );
  }

  handleRequestAccess = event => {
    event.preventDefault();
    const { username, email, password } = this.state;

    const onSuccess = () => {
      this.setState({
        username: '',
        email: '',
        password: '',
        submitting: false,
        form: 'login',
        message: 'Access requested successfully. You can sign in once an administrator has approved you.'
      });
    }

    const onError = error => {
      this.setState({
        error,
        submitting: false,
      });
    }

    if (!username || !email) {
      this.setState({
        error: 'All fields must be completed'
      });
    } else {
      this.setState({
        error: null,
        submitting: true,
      });

      handleCreateUser(username, email, password, onSuccess, onError);
    }

    return false;
  }

  renderRequestAccessForm = () => {
    const { error, username, email, password, submitting, message } = this.state;
    const buttonDisabled = (!username || !email || !password) || submitting;

    return (
      <Form onSubmit={this.handleRequestAccess}>
        <Headline>
          Request <strong>Access</strong>
        </Headline>
        <Sub href="#" onClick={() => this.setState({ form: 'login'})}>
          Go back to login form
        </Sub>
        { message && <Message>{message}</Message> }
        <FormGroup formOnChange={this.handleInputChange('email')} type="email" label="Email" name="email" value={email} />
        <FormGroup formOnChange={this.handleInputChange('username')} type="text" label="Username" name="username" value={username} />
        <FormGroup formOnChange={this.handleInputChange('password')} type="password" label="Password" name="password" value={password} />
        {error && <FormError>Error: {error}</FormError>}
        <FormSubmit disabled={buttonDisabled}>Request</FormSubmit>
      </Form>
    );
  }

  render() {
    const { form } = this.state;

    return (
      <Root>
        {
          form !== 'login' && (
            <BackButtonWrapper>
              <BackButton to="/login">
                <Close />
              </BackButton>
            </BackButtonWrapper>
          )
        }
        { form === 'login' ? this.renderLoginForm() : this.renderRequestAccessForm() }
      </Root>
    )
  }
}

export default Login

const Root = styled.div`
  overflow-y: auto;
  height: 100%;
  align-items: center;
  jusitfy-content: center;

  @media screen and (min-width: 500px) {
    display: flex;
  }
  `

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 4rem 1rem;
`

const BackButton = styled(Link)`
  background: transparent;
  border: 0;
  opacity: 0.3;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.5s ${cubicBezier};
  user-select: none;

  &:focus {
    outline: none;
  }

  @media screen and (${MQ.lg}) {
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;
  width: 100%;
`

const Headline = styled.span`
  font-size: 20px;
  text-transform: uppercase;
  display: block;
  letter-spacing: 4px;
  margin-bottom: 28px;
`

const Message = styled.span`
  font-size: 20px;
  display: block;
  letter-spacing: 0.1rem;
  margin-bottom: 28px;
  max-width: 500px;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  line-height: 1.3;
`

const Sub = styled.a`
  font-size: 13px;
  display: block;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 80px;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;

  &:hover, &:focus {
    border-color: #000;
  }
`

const FormError = styled.span`
  font-size: 17px;
  text-transform: uppercase;
  display: block;
  letter-spacing: 4px;
  margin-bottom: 3rem;
`

const FormSubmit = styled.button`
  width: 140px;
  height: 140px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #000;
  box-shadow: 0 0 1px 0px #000 inset, 0 0 1px 0px #000;
  line-height: 104px;
  font-size: 13px;
  text-align: center;
  letter-spacing: 4px;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.5s ${cubicBezier};
  user-select: none;
  flex-shrink: 0;

  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    outline: none;
  }
  
  @media screen and (${MQ.lg}) {
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`
